/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import "./App.css";
import ControlsProvider from "./contexts/Controls/provider";
import MobileBreakpointProvider from "./contexts/MobileBreakpoint/provider";
import PlaybackProvider from "./contexts/Playback/provider";
import Player from "./Player/Player";

import { Amplify } from "aws-amplify";
// import { withAuthenticator } from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

function App() {
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <main>
          <h1>Hello {user.username}</h1>
          <h1>Channel 3</h1>
          <button onClick={signOut}>Sign out</button>
          <div className="app">
            <MobileBreakpointProvider>
              <PlaybackProvider>
                <ControlsProvider>
                  <Player />
                </ControlsProvider>
              </PlaybackProvider>
            </MobileBreakpointProvider>
          </div>
        </main>
      )}
    </Authenticator>
  );
}

export default App;
